// module.exports = async function promiseMap(list, fn) {
// 	const output = [];
// 	list = list.slice();
// 	let index = 0;
// 	const importNext = () => {
// 		const curItem = list.shift();
// 		if(curItem) {
// 			return fn(curItem, index ++).then(result => {
// 				output.push(result);
// 				return importNext()
// 			});
// 		} else {
// 			// console.log("Import done");
// 			return output;
// 		}
// 	};

// 	return importNext();
// }

export async function promiseMap(list=null, next = (d, idx) => {}, debug=null) {
	const all = list || [],
		total = all.length,
		done  = [];

	let p = Promise.resolve();
	all.forEach((d, idx) => {
		p = p.then(() => next(d, idx)).then(result => {
			done.push(result);
			debug && console.log(`[_batchAll:${debug}] Done ${done.length} / ${total}`);
		});
		// console.log(`[updateAllFeatureData] Started: ${idx} / ${total}`);
	});
	await p;
	return done;
}
