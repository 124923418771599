import React from 'react';


import styles from './AboutPage.module.scss';

import SubPageLayout from '../layouts/SubPageLayout';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


export default function AboutPage({ ...props }) {
	return (<>
		<SubPageLayout title="What is WalkSafe?">
			<div className={styles.root}>

				<Link to="/"><FontAwesomeIcon icon={faArrowLeft}/></Link>

				<h1>What is WalkSafe?</h1>
				
				<p>I created WalkSafe because a family member of mine moved to a small town where she felt unsafe. I'd heard about similar apps, but I couldn't find any I could recommend or that didn't charge money for basic features.</p>

				<p>Therefore, I created WalkSafe to do one thing, for free - set a timer, and if that timer goes off before you stop it, send an SOS message to your Emergency Contacts (with your GPS location and any notes you leave when you start the timer.)</p>

				<p>You can use this app for any time you might be in danger or might not be able to ask for help. For example, walking at night, or if you or a loved one are at home and have low sugar and might pass out, or any number of other scenarios where you need that added security and peace of mind. Have a different use for the app? I'd love to hear about it - email me at <a href='mailto:josiahbryan@gmail.com'>josiahbryan@gmail.com</a>.</p>

				<p>There's no charge to use WalkSafe, and I plan to keep it that way as long as humanly possible! Of course, donations to cover server and messaging expenses won't be turned down either!</p>

				{/* <p>For Safety Tips or to connect with other WalkSafe users, <a href='https://facebook.com/walksafe.app'>join our Facebook Group</a> - just search for WalkSafe on Facebook or <a href='https://facebook.com/walksafe.app'>click this link.</a></p> */}
				
				<p>WalkSafe was created by 
					{' '}
					<a href='https://instagram.com/josiahbryan'>Josiah Bryan</a> 
					{' '}
					<a href='https://instagram.com/josiahbryan'><FontAwesomeIcon icon={faInstagram}/></a>
				</p>
			</div>

		</SubPageLayout>
	</>);
}