import React from 'react';

import styles from './SubPageLayout.module.scss'
import Head from 'components/Head';

export default function SubPageLayou({ children, title, ...props }) {
	return (<>
		<Head title={title || "WalkSafe"}/>

		<div className={[styles.content, props.className].join(" ")}>
			{children}
		</div>
	</>);
}
