import HomePage        from 'pages/HomePage/HomePage';
import AboutPage       from 'pages/AboutPage/AboutPage';
import SafetyTipsPage  from 'pages/SafetyTipsPage/SafetyTipsPage';
import ECAWelcomePage  from 'pages/ECAWelcomePage/ECAWelcomePage';

const Routes = {
	'/':      { component: HomePage, exact: true },
	'/about': { component: AboutPage },
	'/tips':  { component: SafetyTipsPage },
	'/eca':   { component: ECAWelcomePage },

};

export default Routes;