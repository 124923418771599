import React from 'react';
import styles from './HomePage.module.scss';
import Head from 'components/Head';

import * as Sentry from '@sentry/browser';
import { isPhoneGap } from 'utils/isPhoneGap';

import { FixedSizeList } from 'react-window';

import { fade, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
// import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
// import WifiIcon from '@material-ui/icons/Wifi';
// import BluetoothIcon from '@material-ui/icons/Bluetooth';
import SearchIcon from '@material-ui/icons/Search';

import appStoreApple from 'assets/app-store-icons/apple.png';
import appStoreGoogle from 'assets/app-store-icons/google.png';


import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
// import Checkbox from '@material-ui/core/Checkbox';
// import TextField from '@material-ui/core/TextField';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';

// import { green, orange } from '@material-ui/core/colors';

import {  InputBase, Typography, ListItemAvatar, Avatar, ListSubheader, Fab, Tooltip, IconButton, ButtonBase } from '@material-ui/core';

import { Link } from 'react-router-dom'
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLifeRing, faClock, faCog, faTimes, faPlay, faChevronLeft, faPlus, faSave, faUserMinus,  faChevronRight, faCheck, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import {  faQuestionCircle } from '@fortawesome/free-regular-svg-icons';


import { ServerStore } from 'utils/ServerStore';
import { useRemoteData } from 'utils/useRemoteData';
import { setStatusBarColor } from 'utils/MobileStatusBarColor';

import moment from 'moment';
import { WalkSafeCoreService, MIN_SPINNER_MS } from 'utils/WalkSafeCoreService';
import { GpsLogger } from 'utils/GpsLogger';

import sampleContacts from 'utils/sample-contacts.json';
import { defer } from '../../utils/defer';

const welcomeDoneCacheKey = 'walksafe-welcomeDone';

const CONTACT_NOTIFY_TYPES = [
	{ id: 'SMS',   name: 'Text Message'},
	{ id: 'VOICE', name: 'Automated Telephone Call'},
	{ id: 'BOTH',  name: 'Both Text and Automated Call'},
];

// Enable/disable 911 feature flag in settings
// until we write the actual code
const EMERGENCY_DIALING_ENABLED = false;
	
// import { FormGroup, Label, Button } from 'reactstrap';

export default function HomePage({ ...props }) {

	// const settings = useRemoteData(() => ServerStore.GetSettings());//, 'settingsUpdated');

	// settings.welcomeDone = true; // bypass for testing
	const welcomeDone = window.localStorage.getItem(welcomeDoneCacheKey) === 'true';

	const [ vars, setVars ] = React.useState({ settingsMode: false });
	const [ firstRender, setFirstRender ] = React.useState(true);
	const [ welcomeDoneTriggered, setWelcomeDoneTriggered ] = React.useState(false);
	
	// sosTriggered could happen by local timer, sos button, or server event (over socket / push notify)
	React.useEffect(() => {
		// Sometimes PG doesn't get this right
		setStatusBarColor('#c0454c'); // top color in gradient
		
		let evtTrigger, evtClear, evtStart, evtStop, appResume;

		// sosTriggered and sosClear are emitted by ServerStore when TriggerSOS or ClearSOS methods are
		// called (either on server or on client)
		ServerStore.on('sosTriggered', evtTrigger = () => {
			setVars({ sosMode: true });
		});

		ServerStore.on('sosCleared', evtClear = () => {
			setVars({ sosMode: false });
		});

		// timerStarted and timerStopped are emitted by ServerStore when StartTimer or ClearSOS methods are
		// called (either on server or on client)
		ServerStore.on('timerStarted', evtStart = () => {
			setVars({ timerActive: true });
		});

		ServerStore.on('timerStopped', evtStop = () => {
			setVars({ timerActive: false });
		});

		// Cordova event for when app was in background but brought forward
		document.addEventListener('resume', appResume = async () => {
			// Check to see if SOS active on resume
			ServerStore.GetActiveSOS().then(result => {
				if(result && result.isActive) {
					setVars({ sosMode: true });
				}
			});
		});

		if(firstRender) {
			// This is a one-time retrival of active timer from the server
			ServerStore.GetActiveTimer().then(activeTimer => {
				if(activeTimer && activeTimer.isActive) {
					setVars({ timerActive: true });
				}
			});

			// This is a one-time retrival of active SOS from the server
			ServerStore.GetActiveSOS().then(activeSos => {
				if(activeSos && activeSos.isActive) {
					setVars({ sosMode: true });
				}
			});
		
			setFirstRender(false);
		}

		// This is called when homepage is unmounted
		return () => {
			document.removeEventListener('resume', appResume);
			ServerStore.off('sosTriggered', evtTrigger);
			ServerStore.off('sosCleared',   evtClear);
			ServerStore.off('timerStarted', evtStart);
			ServerStore.off('timerStopped', evtStop);
		}
	}, [firstRender]);


	// Allow boot of the core service to continue after first render
	// (Has no effect if already booted)
	React.useEffect(() => {
		if(welcomeDoneTriggered || welcomeDone) {
			WalkSafeCoreService.allowPermissionRequests();
			WalkSafeCoreService.showWhatsNew();
		}
	}, [ welcomeDone, welcomeDoneTriggered ]);

	const [ draftMode, setDraftMode ] = React.useState(false);

	return (<>
		<Head title="WalkSafe"/>
		<div className={clsx(
				styles.root,
				(!draftMode && !vars.settingsMode) && styles.rootWelcomeMode,
				vars.timerActive && styles.timerActive	
			)}>
			<div className={styles.centeringBlock}>
				{/*!settings.loadDone ? <>
					<div className={styles.WelcomePanel}>
						<SimpleBanner/>
						<SimpleSpinner/>
					</div>	
				</>
				:*/ !welcomeDoneTriggered && !welcomeDone ?
					<WelcomePanel onWelcomeDone={() =>  setWelcomeDoneTriggered(true)}/>
				:
				vars.sosMode ? <>
					<SOSMode/>
				</> 
				:
				vars.settingsMode ? <>
					<SettingsPanel setVars={setVars} vars={vars}/>
				</>
				:
				<div className={styles.HomePanel}>
					<SimpleBanner/>
					<div className={styles.buttons}>
						<TimerButton onDraft={flag => setDraftMode(flag)}/>
						<SOSButton/>
					</div>

					{!vars.timerActive ? <>
						<ButtonBase className={styles.settings} onClick={() => {
							setVars({ settingsMode: !vars.settingsMode });
							if(!vars.settingsMode) 
								setDraftMode(false);
						}}>
							<FontAwesomeIcon icon={faCog}/>
						</ButtonBase>

						<div className={styles.footerLinks}>
							<Link to="/about" className={styles.infoLink}>
								What is <b>W</b>alk<b>S</b>afe
							</Link>

							|

							<Link to="/tips" className={styles.infoLink}>
								Safety Tips
							</Link>
						
						</div>
						<div className={styles.footerSpacingHack}></div>
					</> : ""}
				</div>}
				{/* <Link to="/about" className={styles.infoLink}>
					What is WalkSafe?
				</Link> */}
			</div>
		</div>
	</>);
}

const patchSettings = async (patch, options={instant: false}) => {
	if(SettingsPanel.tid) {
		clearTimeout(SettingsPanel.tid);
	}
	if(!SettingsPanel.patch) {
		SettingsPanel.patch = {};
	}
	SettingsPanel.patch = {
		...SettingsPanel.patch,
		...patch
	};

	if(options.instant) {
		return await ServerStore.UpdateSettings(SettingsPanel.patch);
	}
	// Use setTimeout to buffer updates
	SettingsPanel.tid = setTimeout(async () => {
		/*const res = */await ServerStore.UpdateSettings(SettingsPanel.patch);
		// console.log("[patchSettings]", res, SettingsPanel.patch);
	}, 1000);
};

function ProgressDots({ maxDots=3, dots=0 }) {
	const list = new Array(maxDots).fill(false);
	for(let i=0; i<dots; i++) {
		list[i] = true; 
	}

	return (<>
		<div className={styles.progressDots}>
			{list.map((flag, idx) => 
				<div className={clsx(styles.dot, flag ? styles.filled : styles.empty)} key={idx}>
					<span className={styles.text}>
						{idx + 1 }
					</span>
				</div>
			)}
		</div>
	</>);

}

function WelcomePanel({ ...props }) {
	const [ vars, setVars ] = React.useState({ step: WelcomePanel.lastStep || 1 });

	return (<>
		{vars.step === 1  ?
		<div className={styles.WelcomePanel}>
			<SimpleBanner/>

			<div className={styles.intro}>
				<p>
					WalkSafe lets you set a timer for the time you might be in danger.
				</p>
				<p>
					If you don't (or can't) stop the timer, we send an SOS to your emergency contacts.
				</p>
				<p>
					Our secure cloud servers monitor your timer so that emergency alerts will still be sent, no matter what.
				</p>
			</div>

			<ButtonBase className={clsx(styles.smallButton, styles.greenBackground)}
				onClick={() => setVars({ step: WelcomePanel.lastStep = 2 })}
			>
				<FontAwesomeIcon icon={faChevronRight} fixedWidth/> Get Started
			</ButtonBase>

			{!isPhoneGap ? <>
				<AppStoreLinkWidget/>
			</> : ""}


			{/* <ButtonBase className={clsx(styles.smallButton, styles.sosBackground)}
				onClick={() => window.Alert("TODO")}
			>
				<FontAwesomeIcon icon={faLifeRing} fixedWidth/> Have an Emergency Code?
			</ButtonBase> */}
		</div>
		: 
			<OnboardingPanel {...props}/>
		}
	</>);
}

function OnboardingPanel({ onWelcomeDone, ...props }) {
	const settings = useRemoteData(() => ServerStore.GetSettings()); //, 'settingsChanged');	
	const contacts = useRemoteData(() => ServerStore.GetContacts(), 'contactsChanged');

	const [ pending, setPending ] = React.useState(false);
	const [ vars, setVars ] = React.useState({ step: OnboardingPanel.lastStep || 1 });

	React.useEffect(() => {
		if (OnboardingPanel.refName && vars.step === 1)
			OnboardingPanel.refName.focus();
	});

	return (<>
		<div className={styles.OnboardingPanel}>
			<SimpleBanner/>

			<ProgressDots maxDots={3} dots={vars.step}/>

			<div className={styles.settingsFields}>
				{vars.step === 1 ? <>

					{!settings.loadDone ? 
						<center>
							<SimpleSpinner/>
						</center> 
					: <>
					
						<label>Your Name</label>
						<input 
							type="text" 
							className={styles["form-control"]} 
							ref={e => OnboardingPanel.refName = e}
							placeholder="Name" 
							aria-label="Name"
							defaultValue={settings.name}
							onKeyPress={e => {
								if(e.which === 13 && OnboardingPanel.refPin)
									OnboardingPanel.refPin.focus();
							}}
							onChange={e => patchSettings({
								name: e.target.value
							})}
						/>
						<small className={clsx(styles["form-text"],styles["text-muted"])}>
							Your name is sent to your emergency contacts when an SOS is triggered
						</small>

						<label>Choose a Safety PIN</label>
						<input 
							type="number" 
							className={styles["form-control"]} 
							ref={e => OnboardingPanel.refPin = e}
							placeholder="PIN Code" 
							aria-label="PIN Code"
							defaultValue={settings.sosPasscode}
							onKeyPress={e => {
								if(e.which === 13)
									setVars({ step: OnboardingPanel.lastStep = (contacts.length > 0 ? 3 : 2) })
							}}
							onChange={e => patchSettings({
								sosPasscode: e.target.value
							})}
						/>
						<small className={clsx(styles["form-text"],styles["text-muted"])}>
							Your safety PIN keeps you safe by verifying your identity when canceling the timer or canceling an SOS. Choose a set of numbers short and easy to remember.
						</small>

						{!contacts.loadDone ? 
							<center>
								<SimpleSpinner/>
							</center> 
						: <>
						
							<ButtonBase className={clsx(styles.smallButton, styles.greenBackground)}
								onClick={() => 
									setVars({ step: OnboardingPanel.lastStep = (contacts.length > 0 ? 3 : 2) })
								}>
								Next <FontAwesomeIcon icon={faChevronRight}/>
							</ButtonBase>
						</>}
					</>}
				</> :
				vars.step === 2 ? <>
					<p>You need to add at least one emergency contact.<br/>
					<br/>This should be a person you can trust to help you if they get an SOS message from WalkSafe.</p>

					<ButtonBase className={clsx(styles.smallButton, styles.greenBackground)}
						onClick={async () => {
							/*const contact = */await addNewContactPopup(setPending, {
								onSave:   () => setVars({ step: 3}),
								onCancel: () => window.Alert("Please add a contact to continue")
							});
							// if(contact.id) {
							// 	setVars({ step: 3 });
							// } else {
							// if(!contact.id) {
							// 	window.Alert("Please add a contact to continue");
							// }
						}}
					>
						{pending ? <>
							<SimpleSpinner/>
							Saving ...
						</> : <> 
							<FontAwesomeIcon icon={faPlus}/>
							Add emergency contact ...
						</>}
					</ButtonBase>
				
				</> :
				<>
					<div className={styles.welcomeFinalPage}>
						<p>Thanks! You're all set to start using WalkSafe!<br/>
						{/* <br/>
							If you have any questions or want to connect with us, join our
							{" "}<a href='https://facebook.com/walksafe.app'>Facebook Group</a>. */}
						</p>
					</div>
					<ButtonBase className={clsx(styles.smallButton, styles.timerBackground)}
						onClick={async () => {
							// for future loads
							await ServerStore.WelcomeDone();
							window.localStorage.setItem(welcomeDoneCacheKey, 'true');
							// Trigger home page to close the WelcomePanel and show the normal "home" widgets
							if (onWelcomeDone)
								onWelcomeDone();
						}}
					>
						<FontAwesomeIcon icon={faCheck}/>
						Start Using WalkSafe
					</ButtonBase>
				</>}
			</div>
		</div>
	</>)
}

const useStylesForSampleMaterialUISettings = makeStyles(theme => ({
	root: {
		width: '100%',
		// maxWidth: 360,
		color: 'white',
		backgroundColor: 'transparent', //theme.palette.background.paper,
	},
}));

const outerTheme = createMuiTheme({
	palette: {
	  secondary: {
		main: '#FCF7FF',
	  },
	},
  });
  
function MaterialSettingsPanel({ remoteSettings }) {

	const classes = useStylesForSampleMaterialUISettings();
	// const remoteSettings = useRemoteData(() => ServerStore.GetSettings());

	const contacts = useRemoteData(() => ServerStore.GetContacts(), 'contactsChanged');
	const [ contactsPending, setContactsPending ] = React.useState(false);

	const [ settings, setSettings ] = React.useState({});
	React.useEffect(() => {
		if(remoteSettings.loadDone) {
			if (remoteSettings.emergencyNumber === null) {
				remoteSettings.emergencyNumber = '911';
			}
			if (remoteSettings.disableRunDetection === null) {
				remoteSettings.disableRunDetection = false;
			}
			if (remoteSettings.sendTimerStartStopNotices === null) {
				remoteSettings.sendTimerStartStopNotices = false;
			}
			setSettings({ ...remoteSettings });
		}
	}, [ remoteSettings ]);

	const updateSettings = async (field, value) => {
		patchSettings({ [field]: value });
		setSettings({ ...settings, [field]: value });
	};

	const settingsPrompt = async (field, { title, type: input } = { title: "", input: 'text' }) => {
		// const value = prompt(field === 'sosPasscode' ? 'Safety PIN' : 'Your Name', settings[field]);
		const { value } = await window.Alert({
			title, //: field === 'sosPasscode' ? 'Safety PIN' : 'Your Name',
			input: input || 'text', //: field === 'sosPasscode' ? 'number' : 'text',
			inputValue: settings[field],
			showCancelButton: true,
			inputValidator: value => {
				if(!value) {
					return "You need to input something!"
				}
			},
		});
		if(value) {
			updateSettings(field, value);
		}
	}

	/* <ListItemIcon>
		<WifiIcon />
		<FontAwesomeIcon icon={faLifeRing} fixedWidth size="lg"/>
	</ListItemIcon> */

	return (
		<ThemeProvider theme={outerTheme}>
			{!remoteSettings.loadDone ?
				<SimpleSpinner/> 
				:
			<List 
				// subheader={<ListSubheader>Settings</ListSubheader>} 
				className={classes.root}
				dense>
				{/* <ListSubheader disableSticky className={styles.myListItem}>
					Customizations
				</ListSubheader> */}
				
				<Tooltip title="Change the name of the person registered in this app">
					<ListItem button className={styles.myListItem}
						onClick={() => settingsPrompt('name', { title: 'Your Name' })}>
						<Typography variant='body2'>Your Name</Typography>
						<Typography align='right'>{settings.name}</Typography>
					</ListItem>
				</Tooltip>
				<Tooltip title="Change your safety PIN">
					<ListItem  button className={styles.myListItem} 
						onClick={() => settingsPrompt('sosPasscode', {
							title: 'Safety Pin',
							type: 'number'
						})}>
						<Typography variant='body2'>Safety PIN</Typography>
						<Typography align='right'>{settings.sosPasscode}</Typography>
					</ListItem>
				</Tooltip>
				<Tooltip title="Enable/disable running detection when timer is active">
					<ListItem>
						<ButtonBase
							onClick={() => updateSettings('disableRunDetection', !settings.disableRunDetection)}
						>
							<ListItemText id="switch-list-label-disableRunDetection" primary={`Detect Running via GPS`} />
						</ButtonBase>
						<IconButton onClick={() => {
							window.Alert({
								showCloseButton: true,
								type: 'info',
								title: 'Running Detection',
								html: <>
									<p>When you activate a timer in WalkSafe, we stream your GPS position to our servers.</p>
									<p>Our servers monitor your speed and if it looks like you've started to run while you have a dnager timer activated, we'll automatically reduce the time on the timer to 60 seconds and send you an alert.</p>
									<p>If you aren't safe and can't stop, that's okay! Just keep running! We'll send the SOS if the new timer isn't shut off.</p>
									<p>If you don't want our servers to automatically detect running, just turn this switch off.</p>
								</>,
							})
						}}>
							<FontAwesomeIcon icon={faQuestionCircle} size="sm"/>
						</IconButton>
						<ListItemSecondaryAction>
							<Switch
								edge="end"
								onChange={() => updateSettings('disableRunDetection', !settings.disableRunDetection)}
								checked={!settings.disableRunDetection}
								inputProps={{ 'aria-labelledby': 'switch-list-label-disableRunDetection' }}
							/>
						</ListItemSecondaryAction>
					</ListItem>
				</Tooltip>
				<Tooltip title="Enable/disable text messages to your contacts when you start/stop a timer">
					<ListItem>
						<ButtonBase
							onClick={() => updateSettings('sendTimerStartStopNotices', !settings.sendTimerStartStopNotices)}
						>
							<ListItemText id="switch-list-label-sendTimerStartStopNotices" primary={`Send a message when you start/stop timers`} style={{ lineHeight: .9, textAlign: 'left' }}/>
						</ButtonBase>
						{/* <IconButton onClick={() => {
							window.Alert({
								showCloseButton: true,
								type: 'info',
								title: 'Running Detection',
								html: <>
									<p>When you activate a timer in WalkSafe, we stream your GPS position to our servers.</p>
									<p>Our servers monitor your speed and if it looks like you've started to run while you have a dnager timer activated, we'll automatically reduce the time on the timer to 60 seconds and send you an alert.</p>
									<p>If you aren't safe and can't stop, that's okay! Just keep running! We'll send the SOS if the new timer isn't shut off.</p>
									<p>If you don't want our servers to automatically detect running, just turn this switch off.</p>
								</>,
							})
						}}>
							<FontAwesomeIcon icon={faQuestionCircle} size="sm"/>
						</IconButton> */}
						<ListItemSecondaryAction>
							<Switch
								edge="end"
								onChange={() => updateSettings('sendTimerStartStopNotices', !settings.sendTimerStartStopNotices)}
								checked={!!settings.sendTimerStartStopNotices}
								inputProps={{ 'aria-labelledby': 'switch-list-label-sendTimerStartStopNotices' }}
							/>
						</ListItemSecondaryAction>
					</ListItem>
				</Tooltip>
				{EMERGENCY_DIALING_ENABLED ? <>
					<Tooltip title={`Enable/disable dialing ${settings.emergencyNumber || '911'} when an SOS is triggered`}>
						<ListItem>
							<ListItemText id="switch-list-label-sos911" primary={`Dial ${settings.emergencyNumber || '911'} when SOS Triggered`} />
							<IconButton onClick={() => {
									window.Alert({
										showCloseButton: true,
										type: 'info',
										title: `Dial ${settings.emergencyNumber}`,
										html: <>
											<p>When an SOS is triggered (either via a timer or if you click the button), if you have this setting enabled, our app will trigger your phone's dialing app to dial the emergency number you specify.</p>
											<p>
												This is done natively on your phone because your local emergency services will need your phone's GPS location. We aren't technically allowed to dial {settings.emergencyNumber} from our servers since we are not in the same location as you.
											</p>
											<p>By default, it is 911, but you can change it by enabling this setting and changing the setting that appears.</p>
											<p>This feature is not guaranteed to be supported on every phone or every device.</p>
										</>,
									})
								}}>
									<FontAwesomeIcon icon={faQuestionCircle} size="sm"/>
								</IconButton>
							<ListItemSecondaryAction>
								<Switch
									edge="end"
									onChange={() => updateSettings('dialEmergencyNumber', !settings.dialEmergencyNumber)}
									// By using "!!" we force a true/false value.
									// If null on first render then true later,
									// react thows a "uncontrolled>controlled" error
									checked={!!settings.dialEmergencyNumber}
									inputProps={{ 'aria-labelledby': 'switch-list-label-sos911' }}
								/>
							</ListItemSecondaryAction>
						</ListItem>
					</Tooltip>
					{settings.dialEmergencyNumber ?
						<Tooltip title="Change emergency number to dial">
							<ListItem button className={styles.myListItem} 
								onClick={() => settingsPrompt('emergencyNumber', {
									title: 'Emergency Number',
									type: 'number'
								})}>
								<Typography variant='body2'>Emergency Number</Typography>
								<Typography align='right'>{settings.emergencyNumber || '911'}</Typography>
							</ListItem>
						</Tooltip>
					: ""}
				</> : ""}
				<ListSubheader disableSticky className={styles.myListItem}>
					<span>Emergency Contacts</span>
					<Tooltip title="Add new contact">
						<Fab size="small" onClick={async () => {
								await addNewContactPopup(setContactsPending);
								ServerStore.emit('contactsChanged');
							}}>
							<FontAwesomeIcon icon={faPlus}/>
						</Fab>
					</Tooltip>
				</ListSubheader>
				
				{contactsPending || !contacts.loadDone ? 
					<ListItem>
						<SimpleSpinner/>
					</ListItem>
				: ""}

				{Array.from(contacts || []).map(contact =>
					<Tooltip title="Edit this contact" key={contact.id} >
						<ListItem button onClick={async () => {
								const changed = await editContactPopup(contact, setContactsPending);
								if (changed)
									ServerStore.emit('contactsChanged');
							}}>
								<ListItemAvatar>
									<Avatar>
										{contact.name.substring(0,1)}
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary={contact.name}/>
						</ListItem>
					</Tooltip>
				)}
			</List>
			}
		</ThemeProvider>
	);
}

function SettingsPanel({ ...props }) {
	const ver      = useRemoteData(() => ServerStore.appVersion());
	const settings = useRemoteData(() => ServerStore.GetSettings()); //, 'settingsChanged');

	return (<>
		<div className={styles.settingsPanel}>
			<ButtonBase className={styles.settings} onClick={() => {
				props.setVars({ settingsMode: !props.vars.settingsMode })
			}}>
				<FontAwesomeIcon icon={faChevronLeft}/>
			</ButtonBase>

			<h1 className={styles.banner}>Settings</h1>

			{!settings.loadDone ?
				<SimpleSpinner/>	
			: 
			<div className={styles.settingsFields}>
				<MaterialSettingsPanel remoteSettings={settings}/>
				
				<small className={clsx(styles.versionInfo, styles['text-muted'])}>
					App Version {ver.runningVer}<br/>
					Built {ver.runningBuildTime}<br/>
					({moment(new Date(ver.runningBuildTime)).fromNow()})<br/>
					User ID {ServerStore.currentUser.id}
				</small>
			</div>}
		</div>
	</>);
}

const useStylesForChooseNativeContactsUI = makeStyles(theme => ({
	root: {
		width: '100%',
		// maxWidth: 360,
		color: '#333',
		backgroundColor: 'transparent', //theme.palette.background.paper,
		flexGrow: 1,

	},

	// These search styles adapted from https://github.com/mui-org/material-ui/blob/master/docs/src/pages/components/app-bar/SearchAppBar.js
	  search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
		  backgroundColor: fade(theme.palette.common.white, 0.25),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
		  marginLeft: theme.spacing(1),
		  width: 'auto',
		},
	  },
	  searchIcon: {
		width: theme.spacing(7),
		height: '100%',
		left: '.5rem',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	  },
	  inputRoot: {
		color: 'inherit',
	  },
	  inputInput: {
		padding: theme.spacing(1, 1, 1, 7),
		marginLeft: '.75rem',
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
		  width: 120,
		  '&:focus': {
			width: 200,
		  },
		},
	  },
}));

async function getNativeContacts() {
	const sortFn = (a,b) =>
		a.displayName > b.displayName ? +1 :
		a.displayName < b.displayName ? -1 : 0,
		normalFn = contact => {
			const normal = {
				name: contact.displayName || ''
			};

			contact.phoneNumbers.forEach(phone => {
				if(phone.type === 'MOBILE') {
					normal.cellPhone = phone.normalizedNumber;
				}
			});

			return normal;
		},
		hasCell = contact => contact.cellPhone,
		processNativeData = contacts => {
			const normalized = contacts
				.sort(sortFn)
				.map(normalFn)
				.filter(hasCell);
			console.log("[processNativeData] ", { contacts, normalized });
			return normalized;
		} 
	

	return new Promise(resolve => {
		if(!navigator.contactsPhoneNumbers) {
			setTimeout(() => {
				resolve({ data: processNativeData(sampleContacts) });
			}, 500);
		} else {
			navigator.contactsPhoneNumbers.list(function(contacts) {
				console.log(contacts.length + ' contacts found');
				resolve({ data: processNativeData(contacts)  });
				}, function(error) {
				console.error(error);
				resolve({ error });
				});
		}
	});
}

function ChooseContactsUI({ contacts, onChoose }) {
	const classes = useStylesForChooseNativeContactsUI();

	// const contacts = useRemoteData(() => getNativeContacts());

	const [ filteredContacts, setFilteredContacts ] = React.useState( {} );
	const [ searchText, setSearchText ] = React.useState("");
	const [ lastSearched, setLastSearched ] = React.useState("");	

	React.useEffect(() => {
		if(searchText) {
			if(searchText !== lastSearched) {
				const txt = searchText.toLowerCase().trim();
				const filtered = {
					data: contacts.data.filter(contact => {
						return contact.name.toLowerCase().includes(txt);
					})
				}
				setFilteredContacts(filtered)
				setLastSearched(searchText);
			}
		} else {
			setFilteredContacts(contacts);
		}

	}, [ searchText, lastSearched, contacts ])
	

	return (<>
		<ThemeProvider theme={outerTheme}>
			<div className={classes.search}>
				<div className={classes.searchIcon}>
					<SearchIcon />
				</div>
				<InputBase
					placeholder="Search…"
					classes={{
						root: classes.inputRoot,
						input: classes.inputInput,
					}}
					onChange={e => setSearchText(e.target.value)}
					inputProps={{ 'aria-label': 'search' }}
				/>
			</div>
			<List 
				// subheader={<ListSubheader>Settings</ListSubheader>} 
				className={clsx(classes.root, styles.nativeContactsImportList )}
				dense>
			
				{contacts.error ?
					<ListItem>
						<p>Error loading contacts: {"" + contacts.error}</p>
					</ListItem>
				:
					<FixedSizeList
						height={300}
						itemCount={(filteredContacts.data || []).length}
						itemSize={50}
						width={300}>
						{({index, style}) => {
							const contact = filteredContacts.data[index] || {};
							return (
								<Tooltip title="Add this person as an emergency contact" 
									key={contact.id}
									style={style}>
									<ListItem button onClick={async () => {
											if(onChoose)
												onChoose(contact);
										}}>
											<ListItemAvatar>
												<Avatar>
													{(contact.name || ' ').substring(0,1)}
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary={contact.name}/>
									</ListItem>
								</Tooltip>
							)
						}}	
					</FixedSizeList>
					
				}
			</List>
		</ThemeProvider>
	</>)
}

async function addNewContactPopup(setPendingCallback, options={ onSave: () => {}, onCancel: () => {} }) {
	const contactData = {};

	let saving = false;

	const saveNewContact = async (nativeData={}) => {
		if(nativeData) {
			if(nativeData.name) {
				contactData.name = nativeData.name;
			}
			if(nativeData.cellPhone) {
				contactData.cellPhone = nativeData.cellPhone;
			}
		}

		const valid = contactData.name && (
			// contactData.email ||
			contactData.cellPhone
		);
		if(!valid) {
			return window.Alert({
				type: 'warning',
				title: 'Missing Data',
				text: 'Sorry, you need to fill in both the name and cell phone to add this person as an emergency contact.'
			});
		}

		saving = true;
		window.AlertManager.close();

		if(!contactData.notifyType)
			contactData.notifyType = 'BOTH';
		
		console.log("[saveNewContact] ", contactData);

		// Only show spinner if longer than MIN_SPINNER_MS
		const tid = setPendingCallback && setTimeout(() => setPendingCallback(true), MIN_SPINNER_MS);
		
		const serverContact = await ServerStore.AddContact(contactData);

		clearTimeout(tid);
		if (setPendingCallback)
			setPendingCallback(false);

		Object.assign(contactData, serverContact); // since contactData is const...
		window.AlertManager.close();

		if (options.onSave)
			options.onSave();
	}

	const sampleData = null;///sampleContacts;
	const nativeContacts = {
		canImport: () => {
			return sampleData || navigator.contactsPhoneNumbers;
		},

		loadContacts: async () => {
			nativeContacts.contacts = await getNativeContacts();
			return nativeContacts.contacts;
		},

		doImport: async () => {
			window.AlertManager.close();

			nativeContacts.importPending = defer();

			let result = {};
			const requestNewDialog = () => {
				window.AlertManager.close();
				result = { requestNewDialog: true };
			}

			const chooseContact = d => {
				window.AlertManager.close();
				result = { data: d };
			}

			await window.Alert({
				title: 'Choose Contact',
				html: (<>
					<ChooseContactsUI contacts={nativeContacts.contacts} onChoose={chooseContact}/>

					<div className={styles.swalButtons}>
						<ButtonBase className={clsx(styles.swalButton, styles.greenBackground)}  onClick={requestNewDialog}>
							{/* <FontAwesomeIcon icon={faPlus} fixedWidth/> */}
							Create New Contact
						</ButtonBase>
						<ButtonBase className={styles.swalButton} onClick={() => window.AlertManager.close()}>
							{/* <FontAwesomeIcon icon={faTimes} fixedWidth/> */}
							Cancel
						</ButtonBase>
					</div>
				</>),
		
				showConfirmButton: false, // hide default OK button
				showCloseButton: true, // show close button top-right
			});

			nativeContacts.importPending.resolve();

			return result;
		}
	}

	if(nativeContacts.canImport()) {
		const contacts = await nativeContacts.loadContacts();
		if(contacts) {
			if(contacts.error) {
				console.error("Error loading contacts:", contacts.error);
				Sentry.captureException(contacts.error); // log without throwing
			}
			else
			if(contacts.data && contacts.data.length > 0) {
				const { requestNewDialog, data } = await nativeContacts.doImport();
				if(!requestNewDialog && !data) {
					return;
				}

				// Assign chose contact into contactData for rendering in popup below
				Object.assign(contactData, data);
			} 
		}
	}

	await window.Alert({
		title: "New Contact",
		type: '',
		html: (<>
			<div className={styles.swalFields}>
				<label>Name</label>
				<input 
					type="text" 
					className={styles["form-control"]} 
					placeholder="" 
					aria-label="Name"
					defaultValue={contactData.name}
					onChange={e => { contactData.name = e.target.value }}
				/>

				<label>Telephone #</label>
				<input 
					type="tel" 
					className={styles["form-control"]} 
					placeholder="" 
					aria-label="Telephone Number"
					defaultValue={contactData.cellPhone}
					onChange={e => { contactData.cellPhone = e.target.value }}
				/>

				<label>Method of Contact</label>
				<select className={styles['custom-select']} 
					defaultValue={'BOTH'}
					onChange={e => contactData.notifyType = e.target.value}>
					{CONTACT_NOTIFY_TYPES.map(({ id, name }) => 
						<option key={id} value={id}>{name}</option>
					)}
				</select>

				{/* <label>Email</label>
				<input 
					type="email" 
					className={styles["form-control"]} 
					placeholder="" 
					aria-label="Email"
					defaultValue={""}
					onChange={e => { contactData.email = e.target.value }}
				/> */}

			</div>

			<div className={styles.swalButtons}>
				<ButtonBase className={clsx(styles.swalButton, styles.greenBackground)}  onClick={saveNewContact}>
					<FontAwesomeIcon icon={faSave} fixedWidth/>
					Save Contact
				</ButtonBase>

				<ButtonBase className={styles.swalButton} onClick={() => window.AlertManager.close()}>
					<FontAwesomeIcon icon={faTimes} fixedWidth/>
					Cancel
				</ButtonBase>
			</div>
		</>),
		showConfirmButton: false, // hide default OK button
		showCloseButton: true, // show close button top-right
	});


	if(nativeContacts.importPending) {
		await nativeContacts.importPending;
	}
	
	if(!saving && options.onCancel) {
		options.onCancel();
	}


	// console.log("Done with popup");
	return contactData;
}

async function editContactPopup(contactData, setPendingCallback) {
	let changed = false;
	const saveContact = async () => {
		changed = true;

		// console.log("[saveContact] ", contactData);
		window.AlertManager.close();
		
		// Only show spinner if longer than MIN_SPINNER_MS
		const tid = setPendingCallback && setTimeout(() => setPendingCallback(true), MIN_SPINNER_MS);
		
		const serverContact = await ServerStore.UpdateContact(contactData);

		clearTimeout(tid);
		if (setPendingCallback)
			setPendingCallback(false);

		Object.assign(contactData, serverContact);
	}

	const deleteContact = async () => {
		changed = true;

		// console.log("[deleteContact]");
		window.AlertManager.close();
		
		// Only show spinner if longer than MIN_SPINNER_MS
		const tid = setPendingCallback && setTimeout(() => setPendingCallback(true), MIN_SPINNER_MS);
		
		await ServerStore.RemoveContact(contactData);

		clearTimeout(tid);
		if (setPendingCallback)
			setPendingCallback(false);
	};

	await window.Alert({
		title: "Edit Contact",
		type: '',
		html: (<>

			<div className={styles.swalFields}>
				<label>Name</label>
				<input 
					type="text" 
					className={styles["form-control"]} 
					placeholder="" 
					aria-label="Name"
					defaultValue={contactData.name}
					onChange={e => { contactData.name = e.target.value }}
				/>

				<label>Telephone #</label>
				<input 
					type="tel" 
					className={styles["form-control"]} 
					placeholder="" 
					aria-label="Telephone Number"
					defaultValue={contactData.cellPhone}
					onChange={e => { contactData.cellPhone = e.target.value }}
				/>

				<label>Method of Contact</label>
				<select className={styles['custom-select']} 
					defaultValue={contactData.notifyType || 'SMS'}
					onChange={e => contactData.notifyType = e.target.value}>
					{CONTACT_NOTIFY_TYPES.map(({ id, name }) => 
						<option key={id} value={id}>{name}</option>
					)}
				</select>

				{/* <label>Email</label>
				<input 
					type="email" 
					className={styles["form-control"]} 
					placeholder="" 
					aria-label="Email"
					defaultValue={contactData.email}
					onChange={e => { contactData.email = e.target.value }}
				/> */}

			</div>

			<div className={styles.swalButtons}>
				<ButtonBase className={clsx(styles.swalButton, styles.greenBackground)}  onClick={saveContact}>
					<FontAwesomeIcon icon={faSave} fixedWidth/>
					Save Changes
				</ButtonBase>

				<ButtonBase className={clsx(styles.swalButton, styles.sosBackground)}  onClick={deleteContact}>
					<FontAwesomeIcon icon={faUserMinus} fixedWidth/>
					Delete Contact
				</ButtonBase>

				<ButtonBase className={styles.swalButton} onClick={() => window.AlertManager.close()}>
					<FontAwesomeIcon icon={faTimes} fixedWidth/>
					Cancel
				</ButtonBase>
			</div>
		</>),
		showConfirmButton: false, // hide default OK button
		showCloseButton: true, // show close button top-right
	});

	// return contactData;
	return changed;
}

function TimerButton({ ...props }) {
	const [ vars, setVars ] = React.useState({ });
	const [ timerDraft, setTimerDraft ] = React.useState({ hours: 0, minutes: 30 });

	// Sync pending state to vars
	WalkSafeCoreService.useServiceCallback(WalkSafeCoreService.createTimer,
		createTimerPending => setVars({ createTimerPending })
	);

	/// Get draft data to preserve last used time, notes and destination
	if(!timerDraft.latestDraftRequested) {
		setTimerDraft({ latestDraftRequested: true });
		ServerStore.GetTimerDraft().then(draft => {
			setTimerDraft({ latestDraftRequested: true, ...draft, minutes: draft.minutes || 30 });
		});
	}

	// Start the countdown timer
	// Note that this is a client-side niceity - the server will
	// monitor the timer independently so even if the app is closed,
	// or device itself crashed, the server will still SOS if not canceled
	// before timer expires
	// The 'timerData' arg is so we can load a timerData struct from server
	// to resume countdown of a timer in progress on the server.
	const startTimer = async (timerData=null) => {
		const startFresh = timerData ? false : true; // just to make UI nicer
		if(!timerData) {
			// Create timer on server
			timerData = await WalkSafeCoreService.createTimer(timerDraft);
			if(!timerData) {
				// Error occurred, popup already displayed
				return setVars({ timerDraft: true }); // return UI to draft state
			}
		}

		// Ensure we have a date to use
		if (timerData.expireDate && !timerData.expireDate.getDate)
			timerData.expireDate = new Date(timerData.expireDate);

		// Simple utility to calculate hours/min/sec from a millisecond difference
		const calculateTimeRemaining = timerData => {
			const milliseconds = timerData.expireDate - Date.now();

			// console.log(milliseconds); 

			// Convert milliseconds to hours/min/sec
			let hours = milliseconds / (60 * 60 * 1000);
			let minutes = 60 * (hours - Math.floor(hours)); 
			let seconds = 60 * (minutes - Math.floor(minutes));

			// Floor them bad boys
			// based on https://stackoverflow.com/a/8043061
			hours   = Math.floor(hours);
			minutes = ('0' + Math.floor(minutes)).slice(-2);
			seconds = ('0' + Math.floor(seconds)).slice(-2);

			return { milliseconds, hours, minutes, seconds };
		}

		clearInterval(TimerButton.tid);

		TimerButton.tid = setInterval(() => {

			// Note: We must rely on 'newVars' instead of 'vars' because
			// vars does not change (even tho we call setVars())
			// between calls to our interval handler. However, setting
			// newVars is sufficient to track state for our interval handler
			// beteween interval calls, while setVars() handles updating
			// the react state and forcing re-render of the UI
			
			const timeRemaining = calculateTimeRemaining(timerData);

			// Update the UI
			setVars(newVars = {
				...newVars,
				cancelPending: TimerButton.cancelPending, // for update, below
				addTimePending: TimerButton.addTimePending, // ^^
				timeRemaining,
				sosDangerZone: timeRemaining.milliseconds <= 1000 * 30,
			});

			// Trigger SOS if timer expires before canceling
			if(timeRemaining.milliseconds <= 1000) {
				clearInterval(TimerButton.tid);
			
				// SOS Triggered by server now ...
				// ServerStore.TriggerSOS();
			}

		}, 1000);

		// We use 'let' to allow us to rewrite
		// this structure in the interval.
		let newVars = {
			timerStarted: true,
			timerData,
			timeRemaining: startFresh ? {
				hours: timerDraft.hours || 0,
				minutes: ('0' + timerDraft.minutes).slice(-2),
				seconds: '00',
			} : calculateTimeRemaining(timerData)
		};

		// console.log("[startTimer] newVars=", newVars);

		setVars(newVars);
	};

	// if(!TimerButton.tid) {
	// 	startTimer({"hours":0,"minutes":30,"includeNotes":true,"includeDestination":true,"timeStarted":new Date("2019-09-11T23:26:07.648Z"),"expireDate":new Date("2019-09-11T23:56:07.648Z")});
	// }

	// if(!TimerButton.tid) {
	// 	startTimer();
	// }

	// Get active timer
	const timerData = useRemoteData(() => ServerStore.GetActiveTimer(), 'timerStopped timerExpireChanged');

	if(timerData && timerData.isActive && !vars.timerStarted) {
		startTimer(timerData);
		// Make sure gps is running
		// because timer could have been activated
		// before device booted so service could not be running
		GpsLogger.instance.start();
	}

	// Listen for sosTriggered because user could press SOS button while timer is running,
	// which would unmount our TimerButton, so we need to clear the interval if that happens
	React.useEffect(() => {
		let sosTriggered, timerStopped, timerExpireChanged, appResume;

		// Cordova event for when app was in background but brought forward
		document.addEventListener('resume', appResume = async () => {
			// Reload timer from server on resume
			const timerData = await ServerStore.GetActiveTimer();
			if(timerData && timerData.isActive) {
				startTimer(timerData);
				// Make sure gps is running
				// because timer could have been activated
				// before device booted so service could not be running
				GpsLogger.instance.start();
			}
		});

		// Server timer stopped or locally
		ServerStore.on('timerStopped', timerStopped = () => {
			clearInterval(TimerButton.tid);
			setVars({ timerDraft: false });
			// GPS stopped in WalkSafeCoreService
		});

		// Timer expire could have changed due to 'RUNNING' event from GPS
		ServerStore.on('timerExpireChanged', timerExpireChanged = timerData => {
			console.log(" *** timerExpireChanged: new data:", timerData);
			startTimer(timerData);
		});

		ServerStore.on('sosTriggered', sosTriggered = () => {
			clearInterval(TimerButton.tid);
		});

		// This is called when button is unmounted
		return () => {
			document.removeEventListener('resume', appResume);
			ServerStore.off('timerStopped', timerStopped);
			ServerStore.off('timerExpireChanged', timerExpireChanged);
			ServerStore.off('sosTriggered', sosTriggered);
		}
	});
	
	const addTimePending = WalkSafeCoreService.usePendingFlag(WalkSafeCoreService.addTimeToTimer);
	const cancelPending  = WalkSafeCoreService.usePendingFlag(WalkSafeCoreService.cancelTimer);

	WalkSafeCoreService.useServiceCallback(
		WalkSafeCoreService.restartTimerHook,
		result => {
			// Stop and restart local UI display
			clearInterval(TimerButton.tid);
			startTimer(result);
		}
	);

	WalkSafeCoreService.useServiceCallback(
		WalkSafeCoreService.stopTimerHook,
		() => {
			// Stop local UI display
			clearInterval(TimerButton.tid);
			setVars({ timerDraft: false });
		}
	);
	

	// Stop the timer when we unmount
	// React.useEffect(() => {
	// 	return () => {
	// 		clearInterval(TimerButton.tid);
	// 	};
	// }, [ vars ]);


	// console.log("[TimerButton] vars:", vars);

	const minutes = [1,3,5,10,15,20,25,30,35,40,45,50,55];
	const hours   = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];

	return (<>
		{vars.timerStarted ?
			<>
				<div className={styles.timerRunningUi}>
					<h1 className={clsx(styles.timerDisplay, vars.sosDangerZone && styles.sosDangerZone)}>
						{addTimePending || cancelPending ? <SimpleSpinner/> :
							<>{vars.timeRemaining.hours}:{vars.timeRemaining.minutes}:{vars.timeRemaining.seconds}</>
						}
					</h1>

					<ButtonBase className={clsx(styles.smallButton, styles.timerBackground, styles.addTimeButton)}
						onClick={WalkSafeCoreService.addTimeToTimer}>
						<FontAwesomeIcon icon={faPlus} fixedWidth/>							
						Add 5 minutes
					</ButtonBase>

					<ButtonBase className={clsx(styles.smallButton, styles.greenBackground, styles.cancelTimerButton)}
						onClick={WalkSafeCoreService.cancelTimer}>
						<FontAwesomeIcon icon={faTimes} fixedWidth/>
						I'm Safe, Cancel Timer
					</ButtonBase>
				</div>
			</>
			:
		vars.createTimerPending ? 
			<>
				<div className={clsx(styles.timerDraftUi)}>
					<SimpleSpinner/>
				</div>
			</>
			:
		vars.timerDraft ?
			<>
				<div className={clsx(styles.timerDraftUi)}>
					<div className={styles.draftInputs}>
						<label>How long will you be in danger?</label>
						<div className={styles.timeSelection}>
							<div className={styles['input-group']}>
								<select className={styles['custom-select']} 
									value={timerDraft.hours}
									onChange={e => setTimerDraft({
										...timerDraft,
										hours: e.target.value
									})}>
									{hours.map(hr => <option key={hr} value={hr}>{hr} hrs</option>)}
								</select>
								{/* <div className={styles['input-group-append']}>
									<label className={styles['input-group-text']}>Hrs</label>
								</div> */}
							</div>
							<div className={styles['input-group']}>
								<select className={styles['custom-select']} 
									value={timerDraft.minutes}
									onChange={e => setTimerDraft({
										...timerDraft,
										minutes: e.target.value
									})}>
									{minutes.map(min => <option key={min} value={min}>{min} min</option>)}
								</select>
								{/* <div className={styles['input-group-append']}>
									<label className={styles['input-group-text']}>Min</label>
								</div> */}
							</div>
						</div>

						{/* {vars.includeDestination ? <>						 */}
							<div className={styles.destinationBox}>
								<div className={styles['input-group']}>
									<input 
										type="text" 
										className={styles["form-control"]} 
										placeholder="Destination" 
										aria-label="Destination"
										defaultValue={timerDraft.destination}
										onChange={e => setTimerDraft({
											...timerDraft,
											destination: e.target.value
										})}
									/>
								</div>
							</div>
						{/* </> : <></>} */}

						{/* {vars.includeNotes ? <> */}
							<div className={styles.noteBox}>
								<div className={styles['input-group']}>
									<textarea 
										type="text" 
										className={styles["form-control"]} 
										placeholder="Notes for Emergency Contacts" 
										aria-label="Notes for Emergency Contacts"
										value={timerDraft.notes || ''}
										onChange={e => setTimerDraft({
											...timerDraft,
											notes: e.target.value
										})}
									/>
								</div>
							</div>
						{/* </> : <></>} */}

						{/* <div className={styles.extraButtons}>
							<ButtonBase className={clsx(styles.smallButton, vars.includeDestination && styles.active)} onClick={e => {
								setVars({ 
									...vars,
									includeDestination: !vars.includeDestination
								});
								e.target.blur && e.target.blur();
							}}>
								<FontAwesomeIcon icon={vars.includeDestination ? faTimes : faMapMarkerAlt}/>
								Destination
							</ButtonBase>

							<ButtonBase className={clsx(styles.smallButton, vars.includeNotes && styles.active)} onClick={e => {
								setVars({ 
									...vars,
									includeNotes: !vars.includeNotes
								});
								e.target.blur && e.target.blur();
							}}>
								<FontAwesomeIcon icon={vars.includeNotes ? faTimes : faStickyNote}/>
								Note
							</ButtonBase>
						</div> */}
						

						<div className={styles.draftButtons}>

							<ButtonBase className={clsx(styles.smallButton, styles.greenBackground)} onClick={() => {
								startTimer();
								if (props.onDraft)
									props.onDraft(false);
							}}>
								<FontAwesomeIcon icon={faPlay}/>
								Start Timer
							</ButtonBase>

							<ButtonBase className={styles.smallButton} onClick={() => {
								setVars({ timerDraft: false });
								if (props.onDraft)
									props.onDraft(false);
							}}>
								<FontAwesomeIcon icon={faTimes}/>
								Cancel
							</ButtonBase>
						</div>
					</div>
				</div>
			</>
			:
		// Default mode:
			<>
				<ButtonBase 
					className={clsx(styles.bigButton, styles.bigTimerButton)} 
					onClick={() => {
						setVars({ timerDraft: true });
						if (props.onDraft)
							props.onDraft(true);
				}}>
					<FontAwesomeIcon icon={faClock}/>
					Start Timer
				</ButtonBase>
			</>
		}
	</>)
}

function SOSMode({ ...props }) {
	const reset = WalkSafeCoreService.clearSOS,
		pending = WalkSafeCoreService.usePendingFlag(reset);

	// React effect hook to start/stop flashing SOS when mounted/unmounted
	WalkSafeCoreService.useFlashingSOS();

	return (<>
		<div className={styles.sosMode}>
			{pending ? 
				<h2><SimpleSpinner/></h2>
				:
				<h1 className={styles.banner}>SOS Sent</h1>
			}

			{!pending ? 
				<ButtonBase onClick={reset} className={clsx(styles.bigButton, styles.greenBackground)}>
					{pending ? <>
						<SimpleSpinner/>
						Clearing ...
					</> : <>
						<FontAwesomeIcon icon={faTimes}/>
						Cancel SOS, I'm safe now
					</>}
				</ButtonBase>
			: ""}
		</div>
	</>);
}

function SOSButton({ ...props }) {
	const sos   = WalkSafeCoreService.sendSOS,
		pending = WalkSafeCoreService.usePendingFlag(sos);

	return (<>
		<ButtonBase onClick={sos} className={clsx(styles.bigButton, styles.bigSosButton)}>
			{pending ? <>
				<SimpleSpinner/>
				Sending ...
			</> : <>
				<FontAwesomeIcon icon={faLifeRing}/>
				SOS
			</>}
		</ButtonBase>
	</>);
}

function SimpleBanner({ style  }) {
	return (<>
		<div className={styles.banner} style={style}>
			<span className={styles.d}>W</span>
			<span className={styles.ecidr}>alk</span>
			<span className={styles.d}>S</span>
			<span className={styles.ecidr}>afe</span>
		</div>
	</>)
}

function SimpleSpinner({ icon=faCircleNotch, style={}, className="" }) {
	return (
		<div className={clsx(styles.simpleSpinner, className)} style={style}>
			<FontAwesomeIcon icon={icon} spin/>
		</div>
	);
}

function AppStoreLinkWidget() {
	return (<div className={styles.appStoreLinks}>
		<label>Use WalkSafe online or install the WalkSafe app</label>
		<a href='https://apps.apple.com/us/app/walksafe-emergency-sos-timer/id1480969402?ls=1' target='_blank' rel="noopener noreferrer">
			<img src={appStoreApple} alt="Apple App Store"/>
		</a>

		<a href='https://play.google.com/store/apps/details?id=com.josiahbryan.walksafe' target='_blank' rel="noopener noreferrer">
			<img src={appStoreGoogle} alt="Google Play Store"/>
		</a>
	</div>);
}