import React from 'react';


import styles from './SafetyTipsPage.module.scss';

import SubPageLayout from '../layouts/SubPageLayout';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


export default function SafetyTipsPage({ ...props }) {
	return (<>
		<SubPageLayout title="Safety Tips from WalkSafe">
			<div className={styles.root}>

				<Link to="/"><FontAwesomeIcon icon={faArrowLeft}/></Link>

				<h1>Safety Tips</h1>
				
				<ul>
					<li><b>Keep your keys ready</b>:
						Even if it's a quick jaunt from your front door to your car, it's a good idea to keep your keys ready at all times. Hold on to them inside your pocket. Then, bring them out holding the proper key in position as you approach your car or front door. This will eliminate fumbling around in your purse for them outside, which will distract you from your surroundings.
					</li>
					<li>Underneath the arm is a pretty sensitive place to pinch.</li>
					<li><b>Walk with confidence</b>:
						Walk with confidence and purpose when you're out solo. Keep your head up and don't be afraid to make eye contact with those you pass. Walk at a steady pace and walk facing traffic to keep yourself visible. If you get lost, don't wander aimlessly. Keep your pace steady and head for the nearest store or restaurant to ask for directions.
					</li>
					<li>The elbow is the sharpest blunt force object on the body</li>
					<li><b>Avoid struggling with lots of bags</b>:
						Struggling with five different bags at night as you make your way into the house can be a fact of life, but you should avoid it whenever possible. Multiple bags can prevent your ability to react quickly should a dangerous situation arise.
					</li>
					<li><b>Avoid hiding spots</b>:
						Parked cars, dark alleys and unlit corners of parking garages are all places that you should avoid when you're out alone. Keep yourself in visible places on the sidewalk or street as much as possible.
					</li>
					<li><b>Keep walkways clear</b>:
						In the winter, it's important to make sure any pathway from your car to your door is clear of ice and snow. Shovel the areas regularly and keep a container of sand, nonclumping cat litter or ice melt handy to control any icy situations. Keeping an extra container in your trunk is a good idea for any ice you encounter away from home.
					</li>
					<li><b>Choose your footwear wisely</b>:
						Those leather pumps may look fantastic, but they won't provide the secure footing you need, especially in the winter when ice and snow are a factor. Choose footwear that is comfortable, such as runners. If necessary, wear one pair of shoes to the office and bring your indoor footwear in a bag.
					</li>

				</ul>
				<p><i>Look for daily/weekly safety tips as push notifications! They'll show up here later, too.</i></p>

			</div>

		</SubPageLayout>
	</>);
}