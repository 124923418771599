import { server } from './ServerUtil';
import gtag from './GoogleAnalytics';
// import { Cat, Level } from './models';
import { EventEmitter } from 'events';
import * as Sentry from '@sentry/browser';
import { DeviceInfo } from './DeviceInfo';
// import { avg } from './geom';
import { mixpanel } from './mixpanel';
import { isPhoneGap } from './isPhoneGap';

import io from 'socket.io-client';

// Load for ad conversion tracking
import './trackingPixels';

// NOTE: We include buildTime from a .json file instead of a simple .txt file because
// webpack will embed the JSON data at build time and so the bundle will end up with
// "buildTime={...}". If we had used a ".txt" file - while they are easier to generate
// in package.json, instead webpack would have given us a static assset URL, e.g.
// "buildTime='./static/assets/build-time.txt'" or something like that. We could fetch that,
// yes, but the purpose of the buildTime var is to indicate when the RUNNING SOFTWARE
// was built, NOT whatever is on the server. So, by embedding JSON in the bundle,
// we will "freeze" the time the bundle was built in stone inside the bundle itself,
// as buildTime.date (see package.json for how this file is generated)
import buildTime from './build-time.json';
// import { defer } from './defer';

// Disable for now until we set this up for this project
// const Sentry = null;

const DISCLAIMER_KEY = 'walksafe-justUpdated';


// Must match what's used on the server - this is for Unicorn on FB
export const FB_APP_ID = "328677907799571";

// for reuse elsewhere, such as LoginScene
export { gtag };

// For local storage of FB token
const TOKEN_KEY     = 'fb-accessToken';

// For common definition
export const LOGIN_EVENT = "login-event";

// For common definition
export const APP_PAUSED_EVENT  = "app-paused-event";
export const APP_RESUMED_EVENT = "app-resumed-event";

// Hide Josiah from MixPanel (still log metrics to server though)
const HIDE_USER_ID_FROM_MIXPANEL = 3;

// Random delay used to simulate network congestion / slow server response times
const randomTestingDelay = async () => {
	// Never leave this on in production again...
	if(process.env.NODE_ENV === 'production')
		return;

	// Only delay in testing
	await new Promise(resolve => setTimeout(() => resolve(), 1500 * Math.random() + 125));
}

export class ServerStore {
	// Patch with info incase we boot before the script in index.html boots
	static isPhoneGap = isPhoneGap;

	// static currentAccount = null;
	static currentUser    = null;

	static _events = new EventEmitter();
	static on(event, callback) {
		this._events.on(event, callback);
	}

	static off(event, callback) {
		this._events.off(event, callback);
	}

	static emit(event, data) {
		this._events.emit(event, data);
	}

	// static models() {
	// 	return { Cat, Level };
	// }

	static server() { return server };

	static async WelcomeDone() {
		await this.autoLogin();
		return this.server().post('/walksafe/welcome_done');
	}

	static async StoreGpsLocation({ lat, lng, accuracy, speed }) {
		const deviceInfo = await this.deviceInfo(),
			sensor = `${deviceInfo.deviceClass}.${deviceInfo.appType}`,
			packet = { lat, lng, accuracy, speed, timestamp: Date.now(), sensor };

		if (this._socket) // only connected if logged in
			this._socket.emit("StoreGpsLocation", packet)
		else {
			// TBD but the idea is that this could be used from a background cordova plugin to post before closing ...
			await this.autoLogin();
			await this.server().post('/walksafe/store_gps_location', packet);
		}
	}

	static async GetActiveTimer() {
		await  this.autoLogin();
		return this.server().get('/walksafe/active_timer');
		// return {};
	}

	static async GetTimerDraft() {
		await  this.autoLogin();
		return this.server().get('/walksafe/timer_draft');
		// console.log("[GetTimerDraft]");
		// return {
		// 	minutes: 1,
		// 	// notes: "Just testing",
		// }
	}

	static async StartTimer(timerData) {
		this.emit('timerStarted');
		await  this.autoLogin();
		await randomTestingDelay();
		return this.server().post('/walksafe/start_timer', timerData);
	}

	static async AddTimeToTimer({ minutes, sosPasscode }) {
		await  this.autoLogin();
		await randomTestingDelay();
		return this.server().post('/walksafe/modify_timer', { minutes, sosPasscode });
	}

	static async CancelActiveTimer(sosPasscode) {
		await  this.autoLogin();
		await randomTestingDelay();
		const result = await this.server().post('/walksafe/cancel_active_timer', { sosPasscode });
		// const result = { canceled: sosPasscode === '1234' };
		if(result.canceled) {
			this.emit('timerStopped');
		}
		return result;
	}

	static async TriggerSOS(sensorData={ 
		lat: null,
		lng: null
	}) {
		await  this.autoLogin();
		await randomTestingDelay();
		this.emit('sosTriggered');
		return this.server().post('/walksafe/trigger_sos', sensorData);
		// return { triggered: true };
	}

	static async ClearSOS(sosPasscode) {
		await  this.autoLogin();
		await randomTestingDelay();
		const result = this.server().post('/walksafe/clear_sos', { sosPasscode });
		// const result = { cleared: sosPasscode === '1234' };
		if(result.cleared) {
			this.emit('sosCleared');
		} 
		return result;
	}

	static async GetActiveSOS() {
		await  this.autoLogin();
		return this.server().get('/walksafe/active_sos');
		// return { isActive: false };
	}

	static async GetSettings() {
		const result = await  this.autoLogin({ disableAutoSignup: true });
		// console.log("[GetSettings] autoLogin result:", result);
		if(!result) {
			return {}
		}
		await randomTestingDelay();
		return this.server().get('/walksafe/settings');
		// await new Promise(resolve => setTimeout(() => resolve(), 2000));
		// return this._fakeSettings || (this._fakeSettings = {
		// 	name: 'Foobar',
		// 	cellPhone: '7652150511', // for re-login
		// 	sosPasscode: '', // stored hashed on server
		// });
	}

	static async UpdateSettings(settings={
		name: "",
		cellPhone: "",
		sosPasscode: ""
	}) {
		// this.emit('settingsUpdated');

		console.log("[UpdateSettings] posting:", settings);
		await  this.autoLogin({ anonymousName: settings.name });
		await randomTestingDelay();
		return this.server().post('/walksafe/settings/update', settings);
		// Object.assign(this._fakeSettings, settings);

		// return { set: true };
	}

	static async GetContacts() {
		await  this.autoLogin();
		await randomTestingDelay();
		return this.server().get('/walksafe/contacts');
	}

	static async AddContact(data) {
		await  this.autoLogin();
		await randomTestingDelay();
		return this.server().post('/walksafe/contacts', data);
	}

	static async UpdateContact(data) {
		await  this.autoLogin();
		await randomTestingDelay();
		return this.server().post('/walksafe/contacts/update', data);
	}

	static async RemoveContact({ id }) {
		await  this.autoLogin();
		await randomTestingDelay();
		return this.server().post('/walksafe/contacts/remove', { id });
	}

	static async countMetric(metric, value=1) {
		
		this.metric(metric + '.count', value, {}, true/*dontSendToMixpanel*/);

		// For now, just dumps to mixpanel and fakes it (must sum() serverside later) in local metric
		if (mixpanel) {
			if(this.currentUser && this.currentUser.id === HIDE_USER_ID_FROM_MIXPANEL)
				return;
			
			mixpanel.people.increment(metric, value);

			// // special-case spending count for
			// // logging as shown in https://developer.mixpanel.com/docs/javascript#section-tracking-revenue
			// // This metric is currently logged in MarketUtils in BuyItemButton.processPurchaseToken
			// if (metric === 'game.count.dollars_spent') {
			// 	mixpanel.people.track_charge(value);
			// }
		}
	}

	static metric(metric, value, data={}, dontSendToMixpanel=false) {
		(this.metrics || (this.metrics = [])).push({
			// NB user, cat, and level all applied server-side to this item
			// based on the auth token and cat state in the db
			datetime: new Date(),
			epoch:    Date.now(),
			
			metric,
			value,
			data,
		});
		this._touchMetricInterval();

		// Upload to mixpanel as well
		if (mixpanel && !dontSendToMixpanel) {
			if(!this.currentUser || this.currentUser.id !== HIDE_USER_ID_FROM_MIXPANEL) {
				let props;
				if((value !== undefined && value !== null) || Object.keys(data || {}).length > 0) {
					props = { value, ...(data || {})};
				}

				mixpanel.track(metric, props);
			}
		}

		return {
			flush: this._flushMetrics || (this._flushMetrics = this.postMetrics.bind(this)),
		};
	}

	static _touchMetricInterval() {
		if(this._metricInterval)
			return;

		this._metricInterval = setInterval(() => {
			this.postMetrics();
		}, 1000);
	}

	
	static async postMetrics(keepalive=false) {

		const metrics = (this.metrics || []);
		if(metrics.length > 0) {
			const deviceInfo = await this.deviceInfo();

			// Make a copy and then reset .metrics instead of resetting after tx
			// because the tx is async and doesn't block the rest of the program,
			// so metrics could be added (and then lost) during the tx if we waited
			// to reset after the post finished.
			const batch = metrics.slice();
			this.metrics = [];
			// If not logged in yet, post to an unauth'd route
			const preAuth = ServerStore.currentUser ? '' : '/pre';
			// NB: Not using { autoRetry: true } arg on server.post
			// because we just catch errors and re-buffer the metrics for later posting
			// at the next call of the _metricInterval interval timer
			await server.post('/walksafe/metrics' + preAuth, { 
				deviceId: deviceInfo.deviceId, 
				batch
			}, { 
				// Options in this hash passed directly to fetch()
				// Per https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch:
				// 		The keepalive option can be used to allow the request to outlive the page. 
				// 		Fetch with the keepalive flag is a replacement for the Navigator.sendBeacon() API. 
				// Since we could be calling postMetrics() in onbeforeonload (or other page-ending circumstances),
				// this ensures that the metrics hit the server.
				// We have to use fetch() instead of sendBeacon() because we need headers
				// to contain our auth data so the correct user is tracked with the metrics as well (if logged in)
				keepalive
			}).catch(error => {
				// Put metrics back on the stack if an error occurred
				this.metrics.unshift(...batch);
				console.warn("Error posting metrics to server:", error);
			});
		}
	}
	
	static async authenticated(authData) {
		// console.log("[authenticated] authData=", authData, typeof(authData), authData.length )
		
		this.authData = authData;
		server.setToken(authData.token);

		// Only init if changed
		if(!this.currentUser || this.currentUser.id !== authData.user.id) {

			// this.currentAccount = authData.account;
			this.currentUser = authData.user;

			// Update GoogleAnalytics with userId
			gtag('set', { 'user_id': this.currentUser.id }); // Set the user ID using signed-in user_id.

			// Update Sentry with user data
			this._setupSentry(authData.user);

			// Update MixPanel with user data
			this._setupMixpanel(authData.user);
			
			// Count this metric
			this.countMetric('app.user.login');

			// Connect the socket.io instance
			this._connectSocket();

			// Notify anyone listening
			this.emit(LOGIN_EVENT, this.currentUser);
		}

		return this;
	}

	static _setupSentry(user) {
		if(!Sentry)
			return;

		Sentry.configureScope(scope => {
			const { name, id, email } = user,
				sentryId = '#'+id+':'+name;

			scope.setUser({ email, id: sentryId });
		});
	}

	static async _setupMixpanel(user) {
		if(mixpanel) {
			const { name, id, email } = user,
				sentryId = '#'+id+':'+name;

			const deviceInfo = await this.deviceInfo();

			mixpanel.identify(sentryId);
			mixpanel.people.set({ 
				name,
				email,
				deviceBrand: deviceInfo.brand,
				deviceClass: deviceInfo.deviceClass,
			});
		}
	}

	static async linkFb(accessToken) {
		// Store for future auth without asking FB
		window.localStorage.setItem(TOKEN_KEY, accessToken);

		let updatedUser;
		try {
			updatedUser = await server.post('/user/link_fb', { accessToken }, { autoRetry: true })
			if(updatedUser.error)
				throw updatedUser;
			if(!updatedUser)
				throw new Error("No response from server method");
		} catch(e) {
			console.warn("Error logging in:", e);
			return null;
		}

		// server.call would have already output the error on console
		if(updatedUser.error) {// || confirmation.length) {
			console.warn("Error logging in: ", updatedUser);
			return null;
		}

		// console.warn("[login:confirmation]", { confirmation });
		this.currentUser = updatedUser;
		this._setupSentry(updatedUser);

		return this;
	}

	static async unlinkFb() {
		const deviceInfo = await this.deviceInfo();
		// Store for future auth without asking FB
		window.localStorage.setItem(TOKEN_KEY, deviceInfo.deviceId);


		let updatedUser;
		try {
			updatedUser = await server.post('/user/unlink_fb', { deviceInfo }, { autoRetry: true })
			if(updatedUser.error)
				throw updatedUser;
			if(!updatedUser)
				throw new Error("No response from server method");
		} catch(e) {
			console.warn("Error logging in:", e);
			return null;
		}

		// server.call would have already output the error on console
		if(updatedUser.error) {// || confirmation.length) {
			console.warn("Error logging in: ", updatedUser);
			return null;
		}

		// console.warn("[login:confirmation]", { confirmation });
		this.currentUser = updatedUser;
		
		return this;
	}

	static async login(accessToken) {
		const deviceInfo = await this.deviceInfo();

		// Store for future auth without asking FB
		window.localStorage.setItem(TOKEN_KEY, accessToken);

		let confirmation;
		try {
			confirmation = await server.post('/login/fb', { accessToken, deviceInfo }, { autoRetry: true })
			// console.warn("confirmation is null or confirmation.error:", confirmation);
			if(!confirmation)
				return null;
		} catch(e) {
			console.warn("Error logging in:", e);
			return null;
		}

		// server.call would have already output the error on console
		if(confirmation.error || !confirmation.user) {// || confirmation.length) {
			console.warn("Error logging in: ", confirmation);
			return null;
		}

		// console.warn("[login:confirmation]", { confirmation });
		await this.authenticated(confirmation);

		gtag('event', 'login', { method: 'Token' });

		return this;
	}

	static async anonymousLogin(anonymousName) {
		const deviceInfo = await this.deviceInfo();
		
		let confirmation;
		try {
			confirmation = await server.post('/login/fb', { anonymousLogin: true, anonymousName, deviceInfo });
			if(!confirmation)
				return null;
		} catch(e) {
			console.warn("Error logging:", e);
			return null;
		}

		// console.warn("We got this:", confirmation);

		// server.call would have already output the error on console
		if(confirmation.error || !confirmation.user) {
			console.warn("Cannot anonymousLogin:", confirmation);
			return null;
		}

		// Store for future re-login
		window.localStorage.setItem(TOKEN_KEY, confirmation.user.fbAccessToken);

		// console.warn("[tryTakeoverCode:confirmation]", { confirmation });
		await this.authenticated(confirmation);

		gtag('event', 'login', { method: 'Anonymous' });

		return this;
	}
	
	static async tryTakeoverCode(takeoverCode) {
		const deviceInfo = await this.deviceInfo();
		
		let confirmation;
		try {
			confirmation = await server.post('/login/fb', { takeoverCode, deviceInfo }, { autoRetry: true });
			if(!confirmation)
				return null;
		} catch(e) {
			console.warn("Error logging:", e);
			return null;
		}

		// console.warn("We got this:", confirmation);

		// server.call would have already output the error on console
		if(confirmation.error || !confirmation.user) {
			console.warn("Cannot tryTakeoverCode:", confirmation);
			return null;
		}

		// Store for future re-login
		window.localStorage.setItem(TOKEN_KEY, confirmation.user.fbAccessToken);

		// console.warn("[tryTakeoverCode:confirmation]", { confirmation });
		await this.authenticated(confirmation);

		gtag('event', 'login', { method: 'Takeover' });

		return this;
	}


	static logout() {
		this.authData       = null;
		this.currentUser    = null;
		// this.currentAccount = null;
		server.setToken(null);

		if (this._socket) {
			this._teardownInternalSocketListeners();
			this._socket.disconnect();
		}

		return this;
	}

	static async autoLogin(options={anonymousName: null, disableAutoSignup: false}) {
		if(!this.currentUser) {
			// By marshalling calls with a pendingPromise,
			// we make sure we only login once and wait for other logins to finish
			// in case we call autoLogin() before another call to autoLogin() finishes
			return this.autoLogin.pendingPromise ?
				   this.autoLogin.pendingPromise :
				(  this.autoLogin.pendingPromise = new Promise(async resolve => {
					if(!await this.attemptAutoLogin()) {
						if(options.disableAutoSignup) {
							resolve(null);
						}
						const res = await this.anonymousLogin(options.anonymousName);
						if(!res) {
							throw new Error("AutoLogin failed:" + JSON.stringify(res));
						}
						this.autoLogin.pendingPromise = null;
						resolve(res);
					} else {
						this.autoLogin.pendingPromise = null;
						resolve(true);
					}
				}));
		}

		return true;
	}

	static async attemptAutoLogin(token) {
		if(!token)
			token = window.localStorage.getItem(TOKEN_KEY);
		if(token) {
			return await ServerStore.login(token);
		}

		return false;
	}

	static async deviceInfo() {
		return   this._cachedDeviceInfo ||
				(this._cachedDeviceInfo  = await DeviceInfo.getDeviceInfo());
	}

	static async storePushToken(token) {
		const deviceInfo = await this.deviceInfo();

		// POST to the server
		// No need to await the result, this is a write-only action
		server.post('/user/store_push_token', { deviceInfo, token }, { autoRetry: true });

		// Make return obviously explicit 
		return null;
	}

	static _cachedServerVer;
	/**
	 * Fetches latest build version from server and compares to the version this code was built with
	 *
	 * @static
	 * @returns {object}  {serverVer: string, runningVer: string, needsUpdated: bool}
	 * @memberof ServerStore
	 */
	static async appVersion({ disableCache=false }={ disableCache:false }) {

		const deviceInfo = await this.deviceInfo();

		// Note: We check ver against front end, not API host. 
		// Front end (powered by Netlify) will have the /version.txt, NOT the API server.
		const verCheckHost = this.isPhoneGap ? 'https://walksafe.app' : '';

		let versionFetchFailed = false,
			serverBuildTime = null;

		const runningVer     = process.env.REACT_APP_GIT_REV,
			runningBuildTime = buildTime.date;

		if(disableCache)
			this._cachedServerVer = null;

		const serverVer  =
			this._cachedServerVer ? 
			this._cachedServerVer : 
			this._cachedServerVer = await fetch(verCheckHost + '/version.txt')
				.then(data => {
					serverBuildTime = data.headers.get('last-modified');
					return data.text();
				})
				.then(text => ({
					ver: text.trim().replace("\n", ''),
					buildTime: serverBuildTime
				}))
				.catch(()  => versionFetchFailed = true);
				
		const packet = {
			deviceInfo,
			runningVer,
			runningBuildTime,
			serverVer:       versionFetchFailed ? '(unknown)' : serverVer.ver,
			serverBuildTime: versionFetchFailed ? '(unknown)' : serverVer.buildTime,
			needsUpdated:    versionFetchFailed ? false       : serverVer.ver !== runningVer,
		};

		if(!this._printedVersion && (this._printedVersion = true))
			console.log("[ServerStore.appVersion]", packet);

		return packet;
	}

	static _connectSocket() {
		const socket = io(this.server().urlRoot + '?token=' + this.server().token, {
			// change if there's a different mount point, must match server/app.js 'path:' config for socket.io
			path: '/socket',
			// transports: ['websocket', 'polling'],
		});

		this._socket = socket;

		// // TODO: setup events
		// socket.on('connect', function(socket){
		// 	console.warn("[ServerStore._connectSocket] Socket connected!")
		// });

		// socket.on('test message', function(msg){
		// 	console.log('socket test message: ', msg);
		// 	socket.emit('test message', { thanks: true })
		// });

		// if onSocketEvent called before _connectSocket,
		// handlers accumulate in _socketHandlerBacklog
		if (this._socketHandlerBacklog) {
			this._socketHandlerBacklog.forEach(({ event, callback }) => {
				socket.on(event, callback);
			})
		}

		this._setupInternalSocketListeners();
	}

	static _setupInternalSocketListeners() {
		const lx = (this._internalSocketListenerCache = {});
		[
			'sosCleared',
			'sosTriggered',
			'timerStarted',
			'timerStopped',
			'timerExpireChanged'
		].forEach(eventName =>
			this._socket.on(eventName, lx[eventName] = 
				data => this.emit(eventName, data)
			));
	}

	static _teardownInternalSocketListeners() {
		if(!this._internalSocketListenerCache)
			return;
		Object.keys(this._internalSocketListenerCache).forEach(key => 
			this._socket.off(key, this._internalSocketListenerCache[key])
		);
	}

	// For external modules to listen for updates
	static socket() {
		return this._socket;
	}

	static onSocketEvent(event, callback) {
		if (!this._socket) {
			if(!this._socketHandlerBacklog)
				this._socketHandlerBacklog = [];
			this._socketHandlerBacklog.push({ event, callback });
		} else {
			this._socket.on(event, callback);
		}
	}

	// Define this just for parity
	static offSocketEvent(event, callback) {
		if(!this._socket)
			return;
		this._socket.off(event, callback);
	}

	// First boot stuff - check for updates, etc
	// Called by WalkSafeCoreservice
	static async internalBoot() {
		// Clear the timeout set in index.html since we have entered the app safely
		clearTimeout(window.autoupgradeDeadmanTimeout);
		
		const ver = await ServerStore.appVersion().then(ver => {
			// console.log({ver});

			// Console code is copied from PIXI's "sayHello()" routine
			if (!window.isPhoneGap && !!window.chrome) {
				var args = [
					'\n%c %c %c === WalkSafe ' + ver.runningVer + " ===  %c  %c  Made with love by Josiah Bryan <josiahbryan@gmail.com> https://walksafe.app/  %c %c \u2665%c\u2665%c\u2665 \n\n", 
					'background: #c0454c; padding:5px 0;', 
					'background: #c0454c; padding:5px 0;', 
					'color: #fff; background: #90335a; padding:5px 0;', 
					'color: #fff; background: #c0454c; padding:5px 0;', 
					'color: #fff; background: #4b235d; padding:5px 0;', 
					'color: #fff; background: #4b235d; padding:5px 0;', 
					'color: #4b235d; background: #fff; padding:5px 0;', 
					'color: #29235C; background: #fff; padding:5px 0;', 
					'color: #c0454c; background: #fff; padding:5px 0;'
				];
				window.console.log.apply(console, args);
			} else if (window.console) {
				window.console.log('=== WalkSafe ' + ver.runningVer + ' ===  Made with love by Josiah Bryan <josiahbryan@gmail.com>');
			}

			if(ver.needsUpdated) {
				window.console.log(" ** Version is different on the server (" + ver.serverVer + "), consider reloading or updating ...\n\n");
			}

			return ver;
		});
		
		// First-touch metric (no user, but records deviceId)
		const device = await ServerStore.deviceInfo(); // already cached...
		ServerStore.metric('app.booted.' + device.brand); // categorize by brand

		// For easy debugging
		window.downloadAppManifestUpgrades = url => this.downloadAppManifestUpgrades(url);
		window.updateFrom = url => {
			window.localStorage.setItem(DISCLAIMER_KEY, 'updated');
			this.downloadAppManifestUpgrades(url);
			window.location.reload();
		}

		this.checkForUpdates(ver);
	}

	static async checkForUpdates(ver) {
		if(!ver)
			ver = await ServerStore.appVersion({ disableCache: true });

		if(ver.needsUpdated) {

			// Used to show DISCLAIMER on loading
			window.localStorage.setItem(DISCLAIMER_KEY, 'updated');
			
			if(window._pgStarted) {
				let latestUpdatedVerAttemptedKey = 'pg-walksafe-serverVer',
					latestUpdatedVerAttempted = window.localStorage.getItem(latestUpdatedVerAttemptedKey),
					latestUpdatedVerAttemptedCountKey = 'pg-walksafe-serverVerAttemptCount',
					latestUpdatedVerAttemptedCount = parseFloat(window.localStorage.getItem(latestUpdatedVerAttemptedCountKey));

				if(latestUpdatedVerAttempted !== ver.serverVer) {
					window.localStorage.setItem(latestUpdatedVerAttemptedCountKey,
						latestUpdatedVerAttemptedCount = 0
					);
				} else {
					if(isNaN(latestUpdatedVerAttemptedCount))
						latestUpdatedVerAttemptedCount = 0;

					window.localStorage.setItem(latestUpdatedVerAttemptedCountKey,
						latestUpdatedVerAttemptedCount ++
					);

					window.localStorage.setItem(latestUpdatedVerAttemptedKey, ver.serverVer);
				}

				// By using localStorage to store the version we attempted to upgrade,
				// when we hit this particular block again (assuming it's the same ver),
				// we allow 3 attempts to get it boot, then we continue booting like normal.
				// This is different than the deadman's timeout because this guards against
				// failures in index.html to register updates and prevents a boot cycle lock-in.
				// If a new ver comes out, then the latestUpdatedVerAttempted will not match ver.serverVer again,
				// and latestUpdatedVerAttemptedCount will be reset in that case and we can try with the new ver.
				if(latestUpdatedVerAttemptedCount < 3) {
					// Download asset-manifest from frontend server and store upgrade paths in localStorage
					await this.downloadAppManifestUpgrades();

					// Await .flush to ensure metrics are dumped to server before reload
					await ServerStore.metric('app.upgraded.phonegap', null, ver).flush();

					// // Notify user
					// if(window.confirm("WalkSafe game has been updated to version " + ver.serverVer.toUpperCase() + ", press OK to download update"))
					// 	// Reload to get index.html to boot new files straight from server
						window.location.reload();
				} else {
					// Notify server of failsafe failure
					ServerStore.metric('app.upgraded.phonegap.rejected.reboot_lock_failsafe', null, {
						latestUpdatedVerAttemptedCount,
						latestUpdatedVerAttempted,
						ver
					});
				}
	
			} else {

				// Clear service worker caches
				window.caches && window.caches.keys().then(keys => { 
					keys.forEach(key => {
						// if(key.includes("temp")) {
							console.log("Removing cache for new version update from ", key);
							window.caches.delete(key);
						// }
					})
				});
				
				// Await .flush to ensure metrics are dumped to server before reload
				await ServerStore.metric('app.upgraded.browser', null, ver).flush();

				// // Notify user
				// if(window.confirm("WalkSafe game has been updated to version " + ver.serverVer.toUpperCase() + ", press OK to download update"))
				// 	// Reload page
					window.location.reload();
			}
		} 
	}


	// cacheAliases used for handling development server - in dev, the chunk is 0.js, in prod it's 1.js
	static async downloadAppManifestUpgrades(manifestServerOverride=null, cacheAliases={"0.js":"1.js"}) {
		// download asset-manifest, extract main.js/main.css, and chunks:
		// "main.css": "./static/css/main.f309e651.chunk.css",
		// "main.js": "./static/js/main.6e8268ac.chunk.js",
		// "static/css/1.8866556e.chunk.css": "./static/css/1.8866556e.chunk.css",
		// "static/js/1.d455662b.chunk.js": "./static/js/1.d455662b.chunk.js",
		// Store in app-boot-upgrades

		const cache = {},
			manifestServer = manifestServerOverride ? manifestServerOverride : 
				process.env.NODE_ENV === 'production' ?
					'https://walksafe.app' :
					'https://' + window.location.hostname + ':3000',
			manifestFile   = '/asset-manifest.json',
			manifestUrl    = [manifestServer, manifestFile, '?_=', Date.now()].join(''),
			manifest       = (await fetch(manifestUrl)
				.then(body => body ? body.json() : {})
				.then(json => json && json.files ? json.files : json) // new format?
				.catch(err => {
					console.warn("Error downloading manifest:", err);
					return {};
				})) || {};
			
		console.log("[downloadAppManifestUpgrades] received manifest:", manifest);

		Object.keys(manifest).forEach( key => {
			const file = manifest[key];

			// This regex takes something like "./static/js/main.6e8268ac.chunk.js" and returns:
			// m[1] = "main"
			// m[2] = "css"
			const m = file.match(/static\/(?:.*\/)?([^/]+?)(?:\.[^.]+)?\.chunk\.(js|css)$/);
			if(m) {
				const fileKey = m[1];
				const fileType = m[2];
				const storageKey = `${fileKey}.${fileType}`;

				cache[storageKey]  =  file.startsWith('http') ? file :
					manifestServer + (file.startsWith('./')   ? file.replace(/^.\//, '/') : file);

				// Alias if specified
				if(cacheAliases[storageKey])
					cache[cacheAliases[storageKey]] = cache[storageKey];
					
			} else {
				// console.warn("[installUpdates] Unable to match regex with:" + file);
			}
		});

		console.log("[installUpdates] Storing upgrade list:", cache); //, manifest); // + JSON.stringify(cache));

		window.localStorage.setItem("app-boot-upgrades", JSON.stringify(cache));
	}
}

window.store = ServerStore;
