import React from 'react';


import styles from './ECAWelcomePage.module.scss';

import SubPageLayout from '../layouts/SubPageLayout';

import { Link } from 'react-router-dom';


export default function ECAWelcomePage({ ...props }) {
	return (<>
		<SubPageLayout title="What's an Emergency Contact? - WalkSafe">
			<div className={styles.root}>

				<h1>Thanks for Being an Emergency Contact</h1>
				
				<p>You've been added as SOS Contact for emergency situations by a user of this app.</p>

				<p>In WalkSafe, users set timers for when they may be in danger. If the timer goes off before
						they can stop it, an automated SOS will be sent to you that looks like this: </p>

				<div className={styles.sosSample}>
					<code>
						Emergency SOS<br/>
						John Doe triggered an SOS from this approximate location after a danger timer they started in WalkSafe was not shut off. They were traveling approximately 2 MPH, probably walking. If you don't get the "All Clear" message in less than two minutes, please call the authorities or call the person. 
						{' '}
						<a href='https://www.google.com/maps/search/42.33052,-83.04687' target='_blank' rel="noopener noreferrer">https://www.google.com/maps/search/42.33052,-83.04687</a>

					</code>
				</div>

				<Link to="/about">More about WalkSafe ...</Link>
			</div>

		</SubPageLayout>
	</>);
}