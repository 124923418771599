// Only insert pixels in production builds
if(process.env.NODE_ENV === 'production') {

	if(false) {
		// <!-- Facebook Pixel Code -->
		(function(f,b,e,v,n,t,s)
		{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
		n.callMethod.apply(n,arguments):n.queue.push(arguments)};
		if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
		n.queue=[];t=b.createElement(e);t.async=!0;
		t.src=v;s=b.getElementsByTagName(e)[0];
		s.parentNode.insertBefore(t,s)})(window, document,'script',
		'https://connect.facebook.net/en_US/fbevents.js');
		window.fbq('init', '229088007544068');
		window.fbq('track', 'PageView');

		// removed img since we MUST have scripts to run app, so <noscript> usage is irrelevant 
		// <!-- End Facebook Pixel Code -->
	}

	if(false) {
		// <!-- Snap Pixel Code -->
		(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
		{a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
		a.queue=[];var s='script';var r=t.createElement(s);r.async=!0;
		r.src=n;var u=t.getElementsByTagName(s)[0];
		u.parentNode.insertBefore(r,u);})(window,document,
		'https://sc-static.net/scevent.min.js');

		window.snaptr('init', '18f754df-4d89-43b3-905f-4575f4fd32f1', {
			'user_email': '__INSERT_USER_EMAIL__'
		});
		
		window.snaptr('track', 'PAGE_VIEW');
		// <!-- End Snap Pixel Code -->
	}
}