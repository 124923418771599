import { ServerStore, LOGIN_EVENT } from "./ServerStore";
import { initNativePushPlugin }   from './NativePushPlugin';
import { initFirebasePushPlugin } from './FirebasePushPlugin';
import { defer } from './defer';

export class PushNotifyService {	
	constructor(game) {
		
		// Store game so we can process click URL encoded by pushAlert() on the server as a base64 string
		// this.game = game;

		// We init AFTER user logs in because, regardless of phonegap or firebase,
		// we must store a "push token", and we store that on OUR server
		// directly linked to the current user and current device. We don't
		// know what user is logged in until, well, after the LOGIN_EVENT,
		// so no use in setting up push (and getting a token), until we can
		// properly store the token, which only is possible after LOGIN_EVENT
		// fires. We "know" this will fire AFTER construction because we
		// "know" that Game.js will create an instance of PushNotifyService() 
		// before it ever can use LoginScene, which is what would trigger the LOGIN_EVENT anyway.
		ServerStore.on(LOGIN_EVENT, this.init);
	}

	// Set a flag so any callback references stored elsewhere know to just silently go away
	destroy() {
		this.isDestroyed = true;
	}

	init = (/*currentUser*/) => {
		if(window.isPhoneGap)
			this.plugin = initNativePushPlugin(this);
		else
			this.plugin = initFirebasePushPlugin(this);
	}

	// Both NativePushPlugin and FirebasePushPlugin will call this when new message is received
	// **IN THE FOREGROUND** and ONLY IN FOREGROUND
	// E.g. if tab is in background or app is in background, the service worker / plugin will
	// handle notification display
	onMessage = async data => {
		if(this.isDestroyed)
			return;

		ServerStore.metric("app.push_service.message.received", null, data);

		if(this.customMessageDisplay) {
			if(await this.customMessageDisplay(data)) {
				this.onMessageClick(data);
			}
		} else {
			const { value } = await window.AlertManager.fire({
				title: data.title,
				text:  data.body,
				type:  'info',
				showCloseButton: true,
				// showCancelButton:  true,
				confirmButtonText: data.appClickActionText || 'Thanks!',
				// cancelButtonText:  'No thanks',
				// reverseButtons:    true
			});

			if(value) // clicked ok...
				this.onMessageClick(data);
		}
	}

	// Called by WelcomeScene and KittyFlySleep (our two main starting points) when they;re ready to accept clicks
	enableBackgroundClickAcceptance() {
		this._enableBackgroundClickAcceptance = true;
		
		if(this._pendingBackgroundClick) {
			const pendingData = this._pendingBackgroundClick;
			this._pendingBackgroundClick = null;

			console.log("[PushNotifyService.enableBackgroundClickAcceptance] accepted, found pending click, processing:", pendingData);
			this.onMessageClick(pendingData);
			return true;
		}

		return null;
	}

	onMessageClick = async data => {
		if(this.isDestroyed)
			return;
		
		// TODO - do something relevant here.
		// console.log("Yay, message clicked:", data);

		if(data.clickedInBackground && 
			!this._enableBackgroundClickAcceptance) {

			// If this click is from a background handler (e.g. service worker while page is NOT loaded, or PhoneGap while app is unloaded)
			// then we wait for our app to finish booting and the game to start accepting clicks.
			console.log("[PushNotifyService.onMessageClick] not accepting background clicks yet, storing for later:", data);

			this._pendingBackgroundClick = data;
			return;
		}

		const uri = data.click_action;
		if(uri) {
			try {
				const { event, ...externalOpts } = JSON.parse(atob(decodeURIComponent(uri.replace(/^.*#walksafe:/,''))));
				console.log("[PushNotifyService.onMessageClick] parsed click_action, got event=", event, ", externalOpts=", externalOpts);

				ServerStore.metric("app.push_service.message.clicked.success", null, { event, externalOpts });
				
				ServerStore.emit(event, externalOpts);
			} catch(e) {
				console.error("[PushNotifyService.onMessageClick] Error processing data.click_action:", e, uri, data);

				ServerStore.metric("app.push_service.message.clicked.processing_error", null, { error: e, uri, data });
			}
		} else {
			console.warn("[PushNotifyService.onMessageClick] no click_action in data:", data);
			ServerStore.metric("app.push_service.message.clicked.no_click_action", null, data);
		}

	}

	// Only used in web app, not PhoneGap
	// Update: Also planning on using with iOS (PhoneGap)
	onPermissionNeeded = async () => {
		if(this.isDestroyed)
			return;
			
		// The game could define this callback ...
		if(this.permissionNeededCallback &&
			await this.permissionNeededCallback()) {

			// Only return (allow request) if permissionNeededCallback returns true,
			// if returns a falsy value, dont return and delay via defer() (possibly forever)
			return;
		}

		if (this._permission) 
			return this._permission;

		this._permission = defer();

		// The FirebasePushPlugin will await this function,
		// so block until this deferred promise is resolved
		await this._permission;
	}

	// At some point, our game can check if permission is needed,
	// show a UI prompt, and if user agrees, then it will call permissionGiven() ...
	async ifPermissionNeeded(callback) {

		// Firebase has not yet came online and told us we needed permission, so store callback
		if(!this._permission && callback) {
			this.permissionNeededCallback = callback;
		}

		// Firebase has told us we needed permission already,
		// so execute callback and resolve deferred promise (if callback returns true)
		if(this._permission 
			&& callback 
			&& await callback()) {
			this.permissionGiven();
			return false;
		}

		return true;
	}

	// This will resolve the blocking promise given in onPermissionNeeded().
	// Firebase will be waiting on this promise to resolve before actually showing the native notify permission request
	permissionGiven() {
		this._permission.resolve();
		this._permission = null;
	}

	
}

// Start the service
PushNotifyService.instance = new PushNotifyService();